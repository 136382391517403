import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { RELEASE_DETAILS_SEO } from 'utils/seo';

import Layout from 'components/layout';
import { Release } from 'components/Releases/Release';

import { GetReleaseDetailsQuery } from 'types';

interface IReleaseDetailsProps {
  data: GetReleaseDetailsQuery;
  location: Location;
}

const ReleaseTemplate: React.FC<IReleaseDetailsProps> = ({ data }) => {
  const {
    post,
    post: {
      slug,
      coverArt: { fluid },
      title,
      description,
      soundcloudUrl,
    },
  } = data;

  return (
    <Layout
      title={RELEASE_DETAILS_SEO.title(title)}
      description={RELEASE_DETAILS_SEO.description(
        title,
        get(description, 'childMarkdownRemark.excerpt')
      )}
      image={RELEASE_DETAILS_SEO.image(fluid.src)}
      pathname={RELEASE_DETAILS_SEO.pathname(slug)}
      audioUrl={RELEASE_DETAILS_SEO.audioUrl(soundcloudUrl)}
    >
      <Release post={post} />
    </Layout>
  );
};

export default ReleaseTemplate;

export const releaseDetailsQuery = graphql`
  query GetReleaseDetails($slug: String!) {
    post: contentfulRelease(slug: { eq: $slug }) {
      slug
      publishDate(formatString: "MMMM DD, YYYY")
      title
      coverArt {
        fluid(maxWidth: 450) {
          ...GatsbyContentfulFluid
        }
      }
      isCompilation
      description {
        childMarkdownRemark {
          excerpt(pruneLength: 155)
          html
        }
      }
      artists {
        name
        slug
        images {
          description
          fluid(maxWidth: 180) {
            ...GatsbyContentfulFluid
          }
        }
        description {
          childMarkdownRemark {
            excerpt
          }
        }
      }
      beatportUrl
      soundcloudUrl
      spotifyUrl
    }
  }
`;
