import React from 'react';
import { artistName, isArtists, isArtistDescription } from 'utils/artists';
import { ARTIST_DETAILS_ROUTE } from 'utils/routes';
import { GetReleaseDetailsQuery } from 'types';

import RadioOrReleaseHeader from '../RadioOrReleaseHeader';
import RadioOrReleaseBody from '../RadioOrReleaseBody';

interface IReleaseDetailsProps {
  post: GetReleaseDetailsQuery['post'];
}

export const Release: React.FC<IReleaseDetailsProps> = ({ post }) => {
  const {
    coverArt: { fluid },
    title,
    description,
    beatportUrl,
    soundcloudUrl,
    spotifyUrl,
    isCompilation,
    artists,
  } = post;

  const releaseArtists = isArtists(artists) && artists;
  const artistDescription = isArtistDescription(description) && description;

  return (
    <>
      <RadioOrReleaseHeader
        title={title}
        artist={artistName(releaseArtists, isCompilation)}
        artistLink={
          isCompilation ? null : ARTIST_DETAILS_ROUTE(artists[0].slug)
        }
        image={fluid}
        beatportUrl={beatportUrl}
        soundcloudUrl={soundcloudUrl}
        spotifyUrl={spotifyUrl}
      />
      <RadioOrReleaseBody
        artists={releaseArtists}
        description={artistDescription}
        streamUrl={soundcloudUrl}
      />
    </>
  );
};
